import { render, staticRenderFns } from "./OrderInfo.vue?vue&type=template&id=0d882980&scoped=true&"
import script from "./OrderInfo.vue?vue&type=script&lang=js&"
export * from "./OrderInfo.vue?vue&type=script&lang=js&"
import style0 from "./OrderInfo.vue?vue&type=style&index=0&id=0d882980&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d882980",
  null
  
)

export default component.exports